import {
  SelectChangeEvent,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import MinMaxFilter from './MinMaxFilter';
import SliderFilter from './SliderFilter';
import { useRecoilValue } from 'recoil';
import {
  Aggressor,
  Filter,
  FilterItem,
  FilterOperator,
  FilterValueFormat,
  OptionsFeedColumnKey,
  OptionTradeSide,
} from 'types/tape';
import MultiOptionSelector from './MultiOptionSelector';
import MultiOptionAutocomplete from './MultiOptionAutocomplete';
import {
  TAPE_COLUMN_TOOLTIPS_MAP,
  TAPE_DEFAULT_FILTER_ID,
  TAPE_SCANNERS,
} from 'config/tape';
import { hiroManifestState, timezoneState, watchlistsState } from 'states';
import OptionsDropdownMultiSelector from './OptionsDropdownSelector';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  addOrUpdateFilters,
  getExpirationFilterItems,
  getFilterValue,
} from 'util/tape';
import { Equity, Scanner } from 'types';
import { tnsEquityScannersDataState } from 'states/tape';
import { getEquitiesForScanner } from 'util/equityhub';
import DateTimeSelector from './DateTimeSelector';
import {
  dayjs,
  generateExpirationTimestamps,
  getAllSymsFromManifest,
  getDateFormatted,
} from 'util/shared';
import GroupedFiltersSection from './GroupedFiltersSection';
import InfoPopper from 'components/shared/InfoPopper';
import { SGTooltip } from 'components/core';

interface FilterPanelProps {
  filters: Filter[];
  onChangeFilters: (newFilters: Filter[]) => void;
  noSym?: boolean;
  viewOnly?: boolean;
  sx?: SxProps<Theme>;
}

const MIN_DAYS = 0;
const MAX_DAYS = 1461; // 4 years

const MIN_PREM = 0;
const MAX_PREM = 1_000_000_000; // 1B

const minMaxFiltersConfig: {
  title: string;
  description?: string;
  format: FilterValueFormat;
  minId: TAPE_DEFAULT_FILTER_ID;
  maxId: TAPE_DEFAULT_FILTER_ID;
  field: OptionsFeedColumnKey;
}[] = [
  {
    title: 'PREMIUM',
    description: TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Premium),
    format: 'currency',
    minId: TAPE_DEFAULT_FILTER_ID.MinPremium,
    maxId: TAPE_DEFAULT_FILTER_ID.MaxPremium,
    field: OptionsFeedColumnKey.Premium,
  },
  {
    title: 'STRIKE',
    description: TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Strike),
    format: 'currency',
    minId: TAPE_DEFAULT_FILTER_ID.MinStrike,
    maxId: TAPE_DEFAULT_FILTER_ID.MaxStrike,
    field: OptionsFeedColumnKey.Strike,
  },
  {
    title: 'VOLUME',
    description: TAPE_COLUMN_TOOLTIPS_MAP.get(
      OptionsFeedColumnKey.DailyVolCumsum,
    ),
    format: 'number',
    minId: TAPE_DEFAULT_FILTER_ID.MinVol,
    maxId: TAPE_DEFAULT_FILTER_ID.MaxVol,
    field: OptionsFeedColumnKey.DailyVolCumsum,
  },
  {
    title: 'O/I',
    description: TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.PrevOi),
    format: 'number',
    minId: TAPE_DEFAULT_FILTER_ID.MinOI,
    maxId: TAPE_DEFAULT_FILTER_ID.MaxOI,
    field: OptionsFeedColumnKey.PrevOi,
  },
  {
    title: 'SIZE',
    description: TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Size),
    format: 'number',
    minId: TAPE_DEFAULT_FILTER_ID.MinSize,
    maxId: TAPE_DEFAULT_FILTER_ID.MaxSize,
    field: OptionsFeedColumnKey.Size,
  },
  {
    title: 'SPOT',
    description: TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.StockPrice),
    format: 'currency',
    minId: TAPE_DEFAULT_FILTER_ID.MinSpotPrice,
    maxId: TAPE_DEFAULT_FILTER_ID.MaxSpotPrice,
    field: OptionsFeedColumnKey.StockPrice,
  },
  {
    title: 'PRICE',
    description: TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Price),
    format: 'currency',
    minId: TAPE_DEFAULT_FILTER_ID.MinOptPrice,
    maxId: TAPE_DEFAULT_FILTER_ID.MaxOptPrice,
    field: OptionsFeedColumnKey.Price,
  },
  {
    title: 'DELTA',
    description: TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Delta),
    format: 'number',
    minId: TAPE_DEFAULT_FILTER_ID.MinDelta,
    maxId: TAPE_DEFAULT_FILTER_ID.MaxDelta,
    field: OptionsFeedColumnKey.Delta,
  },
  {
    title: 'GAMMA',
    description: TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Gamma),
    format: 'number',
    minId: TAPE_DEFAULT_FILTER_ID.MinGamma,
    maxId: TAPE_DEFAULT_FILTER_ID.MaxGamma,
    field: OptionsFeedColumnKey.Gamma,
  },
  {
    title: 'VEGA',
    description: TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Vega),
    format: 'number',
    minId: TAPE_DEFAULT_FILTER_ID.MinVega,
    maxId: TAPE_DEFAULT_FILTER_ID.MaxVega,
    field: OptionsFeedColumnKey.Vega,
  },
  {
    title: 'IV',
    description: TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.IVol),
    format: 'percentage',
    minId: TAPE_DEFAULT_FILTER_ID.MinIV,
    maxId: TAPE_DEFAULT_FILTER_ID.MaxIV,
    field: OptionsFeedColumnKey.IVol,
  },
  {
    title: 'BID',
    description: TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Bid),
    format: 'currency',
    minId: TAPE_DEFAULT_FILTER_ID.MinBid,
    maxId: TAPE_DEFAULT_FILTER_ID.MaxBid,
    field: OptionsFeedColumnKey.Bid,
  },
  {
    title: 'ASK',
    description: TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Ask),
    format: 'currency',
    minId: TAPE_DEFAULT_FILTER_ID.MinAsk,
    maxId: TAPE_DEFAULT_FILTER_ID.MaxAsk,
    field: OptionsFeedColumnKey.Ask,
  },
];

const FilterPanel = memo(
  ({ filters, onChangeFilters, noSym, viewOnly, sx }: FilterPanelProps) => {
    const manifest = useRecoilValue(hiroManifestState);
    const watchlists = useRecoilValue(watchlistsState);
    const eqScanners = useRecoilValue(tnsEquityScannersDataState);
    const userTimezone = useRecoilValue(timezoneState);

    const [now, setNow] = useState(dayjs().tz(userTimezone).valueOf());

    // Update the "currentTime" every 60 seconds
    useEffect(() => {
      const interval = setInterval(() => {
        setNow(dayjs().tz(userTimezone).valueOf()); // Update the "current time" as number in user's timezone
      }, 60_000); // 60 seconds interval

      return () => clearInterval(interval); // Cleanup on component unmount
    }, [userTimezone]); // Depend on userTimezone to recompute if the user's timezone changes

    const onFilterChange = useCallback(
      (filter: Filter) => {
        const updatedFilters = addOrUpdateFilters(filters, [filter]);
        onChangeFilters(updatedFilters);
      },
      [filters, onChangeFilters],
    );

    const selectedWatchlists = useMemo(() => {
      const syms: string[] | undefined = (
        filters.find(
          (f) => f.id === TAPE_DEFAULT_FILTER_ID.Symbols,
        ) as FilterItem
      )?.value as string[];

      if (syms && watchlists) {
        // a watchlist is considered "selected" if every symbol it contains is already added to the symbols filter
        return watchlists
          ?.filter((w) => w.symbols.every((s) => syms.includes(s)))
          .map((w) => `${w.id}`);
      }

      return [];
    }, [filters, watchlists]);

    const onWatchlistSelectChange = useCallback(
      (event: SelectChangeEvent<string | string[]>) => {
        const selectedWatchlistIds = event.target.value as string[];

        if (watchlists) {
          // Extract all watchlist syms
          const watchlistSyms: Set<string> = new Set(
            watchlists.flatMap((w) => w.symbols),
          );
          // Extract symbols from all selected watchlists
          const selectedSymbols = new Set(
            watchlists
              .filter((w) => selectedWatchlistIds.includes(`${w.id}`))
              .flatMap((w) => w.symbols),
          );

          // Check if the filter for symbols already exists
          const existingFilter = filters.find(
            (f) => f.id === TAPE_DEFAULT_FILTER_ID.Symbols,
          ) as FilterItem;

          // Safely handle the existing filter's value (ensure it's an array or use an empty array)
          const existingValue = (existingFilter?.value as string[]) || [];

          // Filter out symbols that are no longer in the selected watchlists
          const updatedSymbols = existingValue.filter(
            (symbol) =>
              selectedSymbols.has(symbol) || !watchlistSyms.has(symbol),
          );

          // Add new symbols from the selected watchlists
          selectedSymbols.forEach((symbol) => updatedSymbols.push(symbol));

          // Create the updated filter array
          const updatedFilters = addOrUpdateFilters(filters, [
            {
              id: TAPE_DEFAULT_FILTER_ID.Symbols,
              field: OptionsFeedColumnKey.Underlying,
              operator: FilterOperator.IsAnyOf,
              value: Array.from(new Set(updatedSymbols)), // Ensure uniqueness
            },
          ]);

          onChangeFilters(updatedFilters);
        }
      },
      [filters, watchlists, onChangeFilters],
    );

    const onSliderFilterChange = useCallback(
      (
        ids: TAPE_DEFAULT_FILTER_ID[],
        field: OptionsFeedColumnKey,
        newValue: number | number[],
      ) => {
        const filtersToUpdate = Array.isArray(newValue)
          ? [
              {
                id: ids[0],
                field,
                operator: FilterOperator.GreaterThanOrEqual,
                value: newValue[0] as number,
              },
              {
                id: ids[1],
                field,
                operator: FilterOperator.LessThanOrEqual,
                value: newValue[1] as number,
              },
            ]
          : [
              {
                id: ids[0],
                field,
                operator: FilterOperator.GreaterThanOrEqual,
                value: newValue as number,
              },
            ];

        const updatedFilters = addOrUpdateFilters(filters, filtersToUpdate);
        onChangeFilters(updatedFilters);
      },
      [filters, onChangeFilters],
    );

    const selectedScanners = useMemo(() => {
      const syms: string[] | undefined = (
        filters.find(
          (f) => f.id === TAPE_DEFAULT_FILTER_ID.Symbols,
        ) as FilterItem
      )?.value as string[];

      if (syms && eqScanners) {
        // a scanner is considered "selected" if every symbol it contains is already added to the symbols filter
        return TAPE_SCANNERS.filter((sc) =>
          getEquitiesForScanner(sc.value, eqScanners).every((e: Equity) =>
            syms.includes(e.sym),
          ),
        ).map((sc) => sc.value);
      }

      return [];
    }, [filters, eqScanners]);

    const onScannerSelectChange = useCallback(
      (event: SelectChangeEvent<string | string[]>) => {
        const selectedScannerIds = event.target.value as Scanner[];

        if (selectedScannerIds) {
          // Get all scanner syms
          const scannerSyms: Set<string> = new Set(
            TAPE_SCANNERS.flatMap((sc) =>
              getEquitiesForScanner(sc.value, eqScanners),
            ).map((eq) => eq.sym),
          );
          // Get unique symbols for all scanners that are currently selected
          const selectedSymbols = new Set(
            selectedScannerIds
              .flatMap((scannerId) =>
                getEquitiesForScanner(scannerId, eqScanners),
              )
              .map((equity: Equity) => equity.sym),
          );

          // Check if the filter for symbols already exists
          const existingFilter = filters.find(
            (f) => f.id === TAPE_DEFAULT_FILTER_ID.Symbols,
          ) as FilterItem;

          // Safely handle the existing filter's value (ensure it's an array or use an empty array)
          const existingValue = (existingFilter?.value as string[]) || [];

          // Filter out symbols that are no longer in the selected scanners
          const updatedSymbols = existingValue.filter(
            (symbol) => selectedSymbols.has(symbol) || !scannerSyms.has(symbol),
          );

          // Add new symbols from the selected scanners
          selectedSymbols.forEach((symbol) => updatedSymbols.push(symbol));

          // Create the updated filter array
          const updatedFilters = addOrUpdateFilters(filters, [
            {
              id: TAPE_DEFAULT_FILTER_ID.Symbols,
              field: OptionsFeedColumnKey.Underlying,
              operator: FilterOperator.IsAnyOf,
              value: Array.from(new Set(updatedSymbols)), // Ensure uniqueness
            },
          ]);

          onChangeFilters(updatedFilters);
        }
      },
      [filters, eqScanners, onChangeFilters],
    );

    // Helper function to handle filter changes for min values
    const handleMinChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      field: OptionsFeedColumnKey,
      minId: TAPE_DEFAULT_FILTER_ID,
    ) => {
      onChangeFilters(
        addOrUpdateFilters(filters, [
          {
            field,
            value: event.target.value,
            id: minId,
            operator: FilterOperator.GreaterThanOrEqual,
          },
        ]),
      );
    };

    // Helper function to handle filter changes for max values
    const handleMaxChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      field: OptionsFeedColumnKey,
      maxId: TAPE_DEFAULT_FILTER_ID,
    ) => {
      onChangeFilters(
        addOrUpdateFilters(filters, [
          {
            field,
            value: event.target.value,
            id: maxId,
            operator: FilterOperator.LessThanOrEqual,
          },
        ]),
      );
    };

    const validateDateTimeChange = (newValue: number | null): boolean => {
      if (newValue === null) {
        return true;
      }

      // Ensure newValue is not in the future
      if (newValue > now) {
        console.warn('Selected datetime cannot be in the future.');
        return false;
      }

      return true; // Valid datetime change
    };

    const handleMinDateTimeChange = (
      field: OptionsFeedColumnKey,
      newValue: number | null,
      id: TAPE_DEFAULT_FILTER_ID,
    ) => {
      // Validate the new "From" datetime before applying the change
      if (validateDateTimeChange(newValue)) {
        onChangeFilters(
          addOrUpdateFilters(filters, [
            {
              field,
              value: newValue,
              id,
              operator: FilterOperator.GreaterThanOrEqual,
            },
          ]),
        );
      }
    };

    const handleMaxDateTimeChange = (
      field: OptionsFeedColumnKey,
      newValue: number | null,
      id: TAPE_DEFAULT_FILTER_ID,
    ) => {
      // Validate the new "To" datetime before applying the change
      if (validateDateTimeChange(newValue)) {
        onChangeFilters(
          addOrUpdateFilters(filters, [
            {
              field,
              value: newValue,
              id,
              operator: FilterOperator.LessThanOrEqual,
            },
          ]),
        );
      }
    };

    const fromDateTime = getFilterValue<number | null>(
      filters,
      TAPE_DEFAULT_FILTER_ID.MinDateTime,
      null,
    );

    const toDateTime = getFilterValue<number | null>(
      filters,
      TAPE_DEFAULT_FILTER_ID.MaxDateTime,
      null,
    );

    const validTnsSyms = useMemo(
      () => getAllSymsFromManifest(manifest),
      [manifest],
    );

    const memoizedMarketDays = useMemo(
      () =>
        generateExpirationTimestamps(
          fromDateTime ? dayjs(fromDateTime) : dayjs(now),
        ),
      [fromDateTime, now],
    );

    return (
      <Stack
        sx={{
          gap: 5,
          ...sx,
        }}
      >
        <Stack sx={{ width: '100%', gap: 1 }}>
          <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
            <SGTooltip
              title={TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Time)}
            >
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  color: 'text.secondary',
                }}
              >
                Timeframe
              </Typography>
            </SGTooltip>

            <InfoPopper
              contents={[
                {
                  label: 'From and To Timeframes',
                  description:
                    "The data displayed is on a maximum of 24 hours basis, where 'to' time uses the last market close time.",
                },
              ]}
            />
          </Stack>
          <Stack sx={{ gap: 3 }}>
            <DateTimeSelector
              label="From"
              value={fromDateTime}
              onChange={(newValue: number | null): void =>
                handleMinDateTimeChange(
                  OptionsFeedColumnKey.Time,
                  newValue,
                  TAPE_DEFAULT_FILTER_ID.MinDateTime,
                )
              }
              min={dayjs('2024-10-24').valueOf()}
              max={now} // Max is "right now"
              disabled={viewOnly}
            />
            <DateTimeSelector
              label="To"
              value={toDateTime}
              onChange={(newValue: number | null): void =>
                handleMaxDateTimeChange(
                  OptionsFeedColumnKey.Time,
                  newValue,
                  TAPE_DEFAULT_FILTER_ID.MaxDateTime,
                )
              }
              min={dayjs('2024-10-24').valueOf()}
              max={now} // Max is "right now"
              disabled={viewOnly}
            />
          </Stack>
        </Stack>

        {!noSym && (
          <>
            <MultiOptionAutocomplete
              options={validTnsSyms}
              value={getFilterValue<string[]>(
                filters,
                TAPE_DEFAULT_FILTER_ID.Symbols,
                [],
              )}
              onChange={(_event: React.SyntheticEvent, value: string[]) =>
                onFilterChange({
                  id: TAPE_DEFAULT_FILTER_ID.Symbols,
                  value,
                  operator: FilterOperator.IsAnyOf,
                  field: OptionsFeedColumnKey.Underlying,
                })
              }
              viewOnly={viewOnly}
            />
            <Stack
              sx={{
                gap: 2,
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <OptionsDropdownMultiSelector
                label="Watchlist"
                value={selectedWatchlists}
                options={
                  watchlists?.map((w) => ({
                    label: w.name,
                    value: `${w.id!}`,
                  })) ?? []
                }
                isMultiple
                onChange={onWatchlistSelectChange}
                viewOnly={viewOnly}
                sx={{ maxWidth: '100%' }}
              />
              <OptionsDropdownMultiSelector
                label="Scanner"
                value={selectedScanners}
                viewOnly={viewOnly}
                options={TAPE_SCANNERS}
                isMultiple
                onChange={onScannerSelectChange}
                sx={{ maxWidth: '100%' }}
              />
            </Stack>
          </>
        )}
        <MultiOptionAutocomplete
          options={memoizedMarketDays}
          placeholder="Select expiration dates"
          optionLabelFormatter={(val: string) =>
            getDateFormatted(parseInt(val))
          }
          value={getFilterValue<string[]>(
            filters,
            TAPE_DEFAULT_FILTER_ID.Expirations,
            [],
          )}
          onChange={(_event: React.SyntheticEvent, value: string[]) => {
            const updatedFilters = addOrUpdateFilters(
              filters,
              getExpirationFilterItems(value),
            );
            onChangeFilters(updatedFilters);
          }}
          viewOnly={viewOnly}
          listBoxProps={{
            maxWidth: '100%',
            height: 300,
          }}
        />

        <SliderFilter
          viewOnly={viewOnly}
          description={TAPE_COLUMN_TOOLTIPS_MAP.get(
            OptionsFeedColumnKey.Expiry,
          )}
          disabled={
            getFilterValue<string[]>(
              filters,
              TAPE_DEFAULT_FILTER_ID.Expirations,
              [],
            )?.length > 0
          }
          header="EXPIRATION"
          name="expiration"
          value={[
            getFilterValue<number>(
              filters,
              TAPE_DEFAULT_FILTER_ID.MinExp,
              MIN_DAYS,
            ),
            getFilterValue<number>(
              filters,
              TAPE_DEFAULT_FILTER_ID.MaxExp,
              MAX_DAYS,
            ),
          ]}
          min={MIN_DAYS}
          max={MAX_DAYS}
          valueFormat="date"
          onChange={(newValue: number | number[]) =>
            onSliderFilterChange(
              [TAPE_DEFAULT_FILTER_ID.MinExp, TAPE_DEFAULT_FILTER_ID.MaxExp],
              OptionsFeedColumnKey.Expiry,
              newValue,
            )
          }
          useLogScale
        />
        <SliderFilter
          viewOnly={viewOnly}
          description={TAPE_COLUMN_TOOLTIPS_MAP.get(
            OptionsFeedColumnKey.Premium,
          )}
          header="PREMIUM"
          name="premium"
          value={getFilterValue<number>(
            filters,
            TAPE_DEFAULT_FILTER_ID.MinPremium,
            0,
          )}
          min={MIN_PREM}
          max={MAX_PREM}
          valueFormat="currency"
          onChange={(newValue: number | number[]) =>
            onSliderFilterChange(
              [TAPE_DEFAULT_FILTER_ID.MinPremium],
              OptionsFeedColumnKey.Premium,
              newValue,
            )
          }
          useLogScale
        />
        <MultiOptionSelector
          viewOnly={viewOnly}
          title="SIDE"
          description={TAPE_COLUMN_TOOLTIPS_MAP.get(
            OptionsFeedColumnKey.TradeSide,
          )}
          options={Object.values(OptionTradeSide).map((ots) => ({
            label: ots.toUpperCase(),
            value: ots,
          }))}
          selectedOptions={getFilterValue<string[]>(
            filters,
            TAPE_DEFAULT_FILTER_ID.Side,
            [],
          )}
          onSelect={(selectedOptions: string[] | string | boolean) =>
            onFilterChange({
              id: TAPE_DEFAULT_FILTER_ID.Side,
              value: selectedOptions as string[],
              operator: FilterOperator.IsAnyOf,
              field: OptionsFeedColumnKey.TradeSide,
            })
          }
        />
        <MultiOptionSelector
          viewOnly={viewOnly}
          singleSelect
          title="AGRESSOR"
          description={TAPE_COLUMN_TOOLTIPS_MAP.get(
            OptionsFeedColumnKey.Aggressor,
          )}
          options={[Aggressor.BUY, Aggressor.SELL].map((a) => ({
            label: a,
            value: a,
          }))}
          selectedOptions={getFilterValue<string>(
            filters,
            TAPE_DEFAULT_FILTER_ID.Aggressor,
            '',
          )}
          onSelect={(selectedOption: string[] | string | boolean) =>
            onFilterChange({
              id: OptionsFeedColumnKey.Aggressor,
              value: selectedOption as string,
              operator: FilterOperator.Equal,
              field: OptionsFeedColumnKey.Aggressor,
            })
          }
        />
        <MultiOptionSelector
          viewOnly={viewOnly}
          singleSelect
          title="CALL/PUT"
          description={TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.IsPut)}
          options={[
            {
              label: 'Put',
              value: true,
            },
            {
              label: 'Call',
              value: false,
            },
          ]}
          selectedOptions={getFilterValue<boolean | ''>(
            filters,
            TAPE_DEFAULT_FILTER_ID.IsPut,
            '',
          )}
          onSelect={(selectedOption: string[] | string | boolean) =>
            onFilterChange({
              id: TAPE_DEFAULT_FILTER_ID.IsPut,
              value: selectedOption as boolean | null,
              operator: FilterOperator.Equal,
              field: OptionsFeedColumnKey.IsPut,
            })
          }
        />
        {minMaxFiltersConfig.map(
          ({ title, description, format, minId, maxId, field }) => (
            <MinMaxFilter
              key={`${title}-${minId}-${maxId}`}
              viewOnly={viewOnly}
              title={title}
              description={description}
              format={format}
              minValue={getFilterValue<string>(filters, minId, '')}
              maxValue={getFilterValue<string>(filters, maxId, '')}
              minPlaceholder={
                format === 'currency'
                  ? '$0'
                  : format === 'percentage'
                  ? '0%'
                  : '0'
              }
              maxPlaceholder={
                format === 'currency'
                  ? '$∞'
                  : format === 'percentage'
                  ? '∞%'
                  : '∞'
              }
              onChangeMin={(event) => handleMinChange(event, field, minId)}
              onChangeMax={(event) => handleMaxChange(event, field, maxId)}
            />
          ),
        )}
        <GroupedFiltersSection
          title="Flags"
          description={TAPE_COLUMN_TOOLTIPS_MAP.get(OptionsFeedColumnKey.Flags)}
        >
          <Stack sx={{ gap: 2 }}>
            <MultiOptionSelector
              viewOnly={viewOnly}
              singleSelect
              title="SWEEP"
              options={BOOLEAN_OPTS}
              selectedOptions={getFilterValue<boolean | ''>(
                filters,
                TAPE_DEFAULT_FILTER_ID.IsSweep,
                '',
              )}
              onSelect={(selectedOption: string[] | string | boolean) =>
                onFilterChange({
                  id: TAPE_DEFAULT_FILTER_ID.IsSweep,
                  value: selectedOption as boolean | null,
                  operator: FilterOperator.Equal,
                  field: OptionsFeedColumnKey.IsSweep,
                })
              }
            />
            <MultiOptionSelector
              viewOnly={viewOnly}
              singleSelect
              title="CROSS"
              options={BOOLEAN_OPTS}
              selectedOptions={getFilterValue<boolean | ''>(
                filters,
                TAPE_DEFAULT_FILTER_ID.IsCross,
                '',
              )}
              onSelect={(selectedOption: string[] | string | boolean) =>
                onFilterChange({
                  id: TAPE_DEFAULT_FILTER_ID.IsCross,
                  value: selectedOption as boolean | null,
                  operator: FilterOperator.Equal,
                  field: OptionsFeedColumnKey.IsCross,
                })
              }
            />
            <MultiOptionSelector
              viewOnly={viewOnly}
              singleSelect
              title="BLOCK"
              options={BOOLEAN_OPTS}
              selectedOptions={getFilterValue<boolean | ''>(
                filters,
                TAPE_DEFAULT_FILTER_ID.IsBlock,
                '',
              )}
              onSelect={(selectedOption: string[] | string | boolean) =>
                onFilterChange({
                  id: TAPE_DEFAULT_FILTER_ID.IsBlock,
                  value: selectedOption as boolean | null,
                  operator: FilterOperator.Equal,
                  field: OptionsFeedColumnKey.IsBlock,
                })
              }
            />
            <MultiOptionSelector
              viewOnly={viewOnly}
              singleSelect
              title="MULTI LEG"
              options={BOOLEAN_OPTS}
              selectedOptions={getFilterValue<boolean | ''>(
                filters,
                TAPE_DEFAULT_FILTER_ID.IsSpread,
                '',
              )}
              onSelect={(selectedOption: string[] | string | boolean) =>
                onFilterChange({
                  id: TAPE_DEFAULT_FILTER_ID.IsSpread,
                  value: selectedOption as boolean | null,
                  operator: FilterOperator.Equal,
                  field: OptionsFeedColumnKey.IsSpread,
                })
              }
            />
          </Stack>
        </GroupedFiltersSection>
      </Stack>
    );
  },
);

const BOOLEAN_OPTS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

FilterPanel.displayName = 'FilterPanel';

export default FilterPanel;
